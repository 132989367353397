<template>
  <div class="d-flex align-center">
    <div>
      <v-text-field
        hide-details
        dense
        outlined
        label="Телефон"
        :class="inputClass"
        :value="maskedPhone"
        @input="handleInput($event)"
        v-mask="{mask: mask, unmaskedVar: 'clearValue'}"
        :error-messages="checkErrors(`detail.userPhones.$each.${index}.phone.phone`)"
        @focus="$event.target.select()"
      ></v-text-field>
    </div>
    <div
      v-if="canRemove"
    >
      <v-btn
        small
        icon
        @click="$emit('delete')"
      >
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>

import phoneInput from '@/components/mixins/phoneInput'
import validationErrors from '@/components/mixins/validationErrors'

export default {
  mixins: [phoneInput, validationErrors],
  name: 'PhoneInput',
  inject: ['$v'],
  watch: {
    phone: {
      handler () {
        this.$v.detail.userPhones.$each[this.index].phone.phone.$model = this.clearValue
        this.$emit('input', this.phone)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
