import Phone from './phone'

class UserPhone {
  constructor () {
    this.id = null
    this.phone = new Phone()
  }
}

export default UserPhone
