<template>
  <div>
    <!--todo: стилизовать ошибку-->
    <h3
      v-if="checkErrors('detail.userPhones').length"
    >{{checkErrors('detail.userPhones')[0]}}</h3>
    <div
      v-for="(phone, index) in localPhones"
      :key="index+'-'+localPhones.length+phone.sort_order"
    >
      <PhoneInput
        :value="phone"
        :index="index"
        @input="handlePhoneChange(index, $event)"
        :can-remove="localPhones.length > 1"
        :mask="phoneMask"
        @delete="handleRemovePhone(index)"
      />
    </div>
    <v-btn
      class="mb-10 mt-4"
      small
      @click="handlePhoneChange(null, newValue())"
    >
      <v-icon>mdi-plus</v-icon>
      Добавить номер
    </v-btn>
  </div>
</template>

<script>
import PhoneInput from '@/components/views/Account/Profile/PhoneList/PhoneInput'
import UserPhone from '@/components/structures/userPhone'
import validationErrors from '@/components/mixins/validationErrors'
import phoneComputed from '@/components/mixins/phoneComputed'
import phoneList from '@/components/mixins/phoneList'

export default {
  mixins: [validationErrors, phoneComputed, phoneList],
  name: 'PhoneList',
  components: { PhoneInput },
  methods: {
    newValue () {
      return new UserPhone()
    }
  },
  inject: ['$v']
}
</script>

<style scoped>

</style>
